import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function Pin({ className }: Props) {
  return (
    <Svg className={className} viewBox="0 0 210 62">
      <Line d="M96 16H94V18H90V20H86V22H82V24H78V26H74V28H68V30H64V32H60V34H56V36H52V38H48V40H42V42H38V44H34V46H30V48H26V50H22V52H16V54H12V56H8V58H4V60H0V62H6V60H12V58H16V56H20V54H24V52H28V50H32V48H38V46H42V44H46V42H50V40H54V38H58V36H64V34H68V32H72V30H76V28H80V26H84V24H90V22H94V20H96V16ZM210 62V60H206V58H202V56H198V54H194V52H190V50H186V48H182V46H178V44H172V42H168V40H164V38H160V36H156V34H152V32H148V30H144V28H140V26H136V24H132V22H128V20H124V18H116.5V20H120V22H124V24H130V26H134V28H138V30H142V32H146V34H150V36H154V38H158V40H162V42H166V44H170V46H174V48H178V50H182V52H186V54H190V56H194V58H198V60H202V62H210Z" />
      <Dot d="M120 8V18H118V22H116V24H112V26H104V24H100V22H98V20H96V14H94V10H96V6H98V4H100V2H102V0H112V2H116V4H118V8H120Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Line = styled.path`
  fill: var(--color-orange);
`

const Dot = styled.path`
  fill: var(--color-cream);
`
