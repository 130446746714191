import { GoogleAnalytics } from 'nextjs-google-analytics'
import { FC } from 'react'

export const GA: FC = () => {
  const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_MEASUREMENT_ID
  if (!GA_MEASUREMENT_ID) {
    console.warn(
      'GA_MEASUREMENT_ID is expected to be defined but got:',
      GA_MEASUREMENT_ID
    )
    return null
  }
  return <GoogleAnalytics trackPageViews gaMeasurementId={GA_MEASUREMENT_ID} />
}
