import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function Round({ className }: Props) {
  return (
    <Svg className={className} viewBox="0 0 25 60">
      <FillWhite d="M0 60V0H0.5V1.5H4.5V3H9V4.5H10.5V6H13.5V7.5H15V9H16.5V10.5H18V12H19.5V15H21V16.5H22.5V21H24V28.5H24.5V33H24V40.5H22.5V43.5H21V46.5H19.5V49.5H18V51H16.5V52.5H15V54H13.5V55.5H10.5V57H7.5V58.5H4.5V60H0Z" />
      <FillBrown d="M1.5 6H0V55.5H1.5V54H4.5V52.5H7.5V51H10.5V49.5H12V48H13.5V46.5H15V43.5H16.5V40.5H18V36H19.5V25.5H18V21H16.5V18H15V15H13.5V13.5H12V12H10.5V10.5H7.5V9H6V7.5H1.5V6Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const FillWhite = styled.path`
  fill: var(--color-white);
`

const FillBrown = styled.path`
  fill: var(--round-color, var(--color-brown));
`
