import styled from '@emotion/styled'

interface Props {
  children: React.ReactNode
}

export default function PageFrame({ children }: Props) {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-brown);
`

const Inner = styled.div`
  @media (min-width: 461px) {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 375px;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 10px var(--color-cream);
    overflow: hidden;

    &::before {
      --width: 375;
      --height: 667;
      content: '';
      display: block;
      padding-top: calc(var(--height) / var(--width) * 100%);
    }
  }
`
