import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

const poppins = {
  black: '/images/poppins/black.png',
  blue: '/images/poppins/blue.png',
  cyan: '/images/poppins/cyan.png',
  green: '/images/poppins/green.png',
  gummy: '/images/poppins/gummy.png',
  orange: '/images/poppins/orange.png',
  purple: '/images/poppins/purple.png',
  red: '/images/poppins/red.png',
  yellow: '/images/poppins/yellow.png',
}

type PoppinType = keyof typeof poppins

interface Props {
  className?: string
  type: PoppinType
}

export default function Poppin({ className, type }: Props) {
  const imageUrl = poppins[type]
  const [size, setSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const img = new Image()
    img.src = imageUrl
    img.onload = () => {
      setSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      })
    }
  }, [imageUrl])

  return (
    <Wrapper className={className} size={size}>
      <Img src={imageUrl} />
    </Wrapper>
  )
}

const stay = keyframes`
  from,
  to {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.06);
  }
`

const Wrapper = styled.div<{ size: { width: number; height: number } }>`
  position: relative;
  width: 60px;

  ${({ size }) => css`
    --width: ${size.width};
    --height: ${size.height};
  `}

  &::before {
    content: '';
    display: block;
    padding-top: calc(var(--height) / var(--width) * 100%);
  }
`

const Img = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: bottom;
  animation: ${stay} 2s steps(6, start) 0s infinite;
`
