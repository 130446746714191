export default {
  home: '/',
  camera: '/camera',
  factory: '/factory',
  factoryDetail: (id: number) => `/factory/${id}`,
  food: '/foods',
  foodLogs: (id: number) => `/food-logs/${id}`,
  poppin: '/poppins',
  poppinDetail: (id: number) => `/poppins/${id}`,
  popbiteListDictionary: '/dictionary/popbites',
  poppinListDictionary: '/dictionary/poppins',
  popbiteDetailDictionary: (id: number) => `/dictionary/popbites/${id}`,
  poppinDiscoverDictionary: (id: number) => `/dictionary/poppins/${id}`,
  notification: '/notifications',
  mint: '/mint',
}
