import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import Effect from './Effect'

export default function TapEffect() {
  const [list, setList] = useState<{ id: number; x: number; y: number }[]>([])

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      setList((list) => [
        ...list,
        { id: Math.floor(Math.random() * 100000), x: e.clientX, y: e.clientY },
      ])
    }
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [setList])

  return (
    <Wrapper>
      {list.map((pos, i) => (
        <Effect
          id={pos.id}
          key={pos.id}
          x={pos.x}
          y={pos.y}
          onAnimationEnd={(id) => {
            setList((list) => list.filter((pos) => pos.id !== id))
          }}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`
