import styled from '@emotion/styled'
import Liquid from './Liquid'
import Round from './Round'

interface Props {
  className?: string
}

export default function BoardBottom({ className }: Props) {
  return (
    <Wrapper className={className}>
      <StyledLiquid />
      <Inner>
        <RoundBottomRight />
        <RoundBottomLeft />
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: calc(100% - 32px);
`

const Inner = styled.div`
  position: relative;
`

const RoundBottomRight = styled(Round)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 22px;
  color: var(--color-cream);
  transform: rotate(270deg);
`
const RoundBottomLeft = styled(Round)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 22px;
  color: var(--color-cream);
  transform: rotate(360deg);
`

const StyledLiquid = styled(Liquid)`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 252px;
  transform: translate(-54%, 100%);
`
