import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function Round({ className }: Props) {
  return (
    <Svg className={className} viewBox="0 0 22 23">
      <Path d="M22 0H0V1H2V9H4V11H6V15H8V17H12V19H14V21H20V23H22V0Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: currentColor;
`
