import { css } from '@emotion/react'
import styled from '@emotion/styled'
import waveTateBrown from './images/wave-tate--brown.svg'
import waveTateOrange from './images/wave-tate--orange.svg'
import waveYokoBrown from './images/wave-yoko--brown.svg'
import waveYokoOrange from './images/wave-yoko--orange.svg'

interface Props {
  className?: string
  direction: 'vertical' | 'horizontal'
  color: 'orange' | 'brown'
}

export default function Wave({ className, direction, color }: Props) {
  return <Wrapper className={className} direction={direction} color={color} />
}

const Wrapper = styled.div<{
  direction: Props['direction']
  color: Props['color']
}>`
  position: relative;

  ${({ direction }) =>
    direction === 'horizontal' &&
    css`
      width: 100%;
      height: 9px;
      background-size: auto 100%;
      background-repeat: repeat-x;
    `}
  ${({ direction }) =>
    direction === 'vertical' &&
    css`
      width: 4px;
      height: 100%;
      min-height: 60px;
      background-size: 100% auto;
      background-repeat: repeat-y;
    `}

  ${({ direction, color }) =>
    direction === 'horizontal' &&
    color === 'brown' &&
    css`
      background-image: url('${waveYokoBrown.src}');
    `}
  ${({ direction, color }) =>
    direction === 'horizontal' &&
    color === 'orange' &&
    css`
      background-image: url('${waveYokoOrange.src}');
    `}
  ${({ direction, color }) =>
    direction === 'vertical' &&
    color === 'brown' &&
    css`
      background-image: url('${waveTateBrown.src}');
    `}
  ${({ direction, color }) =>
    direction === 'vertical' &&
    color === 'orange' &&
    css`
      background-image: url('${waveTateOrange.src}');
    `}
`
