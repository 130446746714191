import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

interface Props {
  id: number
  x: number
  y: number
  onAnimationEnd(id: number): void
}

export default function Effect({ id, x, y, onAnimationEnd }: Props) {
  return (
    <Wrapper
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
      onAnimationEnd={() => {
        onAnimationEnd(id)
      }}
    >
      <Line />
      <Line />
      <Circle />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  --size: 70px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: var(--size);
  height: var(--size);
`

const scale = keyframes`
  from,
  to {
    opacity: 0;
  }
  30%,
  70% {
    opacity: 1;
  }

  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.2);
  }
`

const Circle = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 4px var(--color-cream);
  animation: ${scale} 0.5s ease-in-out 0s both;
`

const slideInOut1 = keyframes`
  from {
    transform: translateY(101%);
  }
  to {
    transform: translateY(-101%);
  }
`
const slideInOut2 = keyframes`
  from {
    transform: translateY(-101%);
  }
  to {
    transform: translateY(101%);
  }
`

const Line = styled.div`
  position: absolute;
  left: 50%;
  width: 4px;
  height: 50%;
  border-radius: 999px;
  overflow: hidden;

  &:nth-child(1) {
    top: 0;
    transform-origin: bottom center;
    transform: rotate(45deg) translateX(-50%) translateY(-40%) translateZ(0);

    &::before {
      animation: ${slideInOut1} 0.5s cubic-bezier(0.7, -0.04, 0.29, 0.98) 0s
        both;
    }
  }
  &:nth-child(2) {
    top: 50%;
    transform-origin: top center;
    transform: rotate(45deg) translateX(-50%) translateY(40%) translateZ(0);

    &::before {
      animation: ${slideInOut2} 0.5s cubic-bezier(0.7, -0.04, 0.29, 0.98) 0s
        both;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: var(--color-cream);
  }
`
