import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function Liquid({ className }: Props) {
  return (
    <Svg className={className} viewBox="0 0 252 52">
      <Path d="M252 0V2H250V16H248V18H244V16H242V2H240V0H226V2H224V14H226V20H228V24H230V26H232V32H234V42H232V46H230V50H226V52H216V50H214V48H212V44H210V30H212V26H214V22H216V18H218V12H220V2H218V0H64V2H62V4H60V26H58V30H50V26H48V4H46V2H44V0H10V2H8V14H6V16H4V14H2V2H0V0H10H44H64H218H226H240H252Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: var(--color-cream);
`
