import * as Sentry from '@sentry/nextjs'
import { Component, ErrorInfo, ReactNode } from 'react'
import Error from '../components/error'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo })
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError && this.state.error !== null) {
      return <Error error={this.state.error} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
