import styled from '@emotion/styled'
import path from '../../constants/path'
import Board from '../common/Board'
import Button from '../common/Button'
import { Link } from '../common/PageTransition'
import Poppin from '../common/Poppin'
import StripeBg from '../common/StripeBg'
import Wave from '../common/Wave'

interface Props {
  error: Error
}

export default function Error({ error }: Props) {
  return (
    <Wrapper>
      <StyledStripeBg />
      <WaveTop>
        <StyledWave direction="horizontal" color="orange" />
      </WaveTop>
      <WaveBottom>
        <StyledWave direction="horizontal" color="orange" />
      </WaveBottom>
      <Gummy type="gummy" />
      <Yellow type="yellow" />
      <StyledBoard title="">
        <Link href={path.home}>
          <TextWrapper>
            <StatusCode>Oops!</StatusCode>
            <Description>{error.toString()}</Description>
          </TextWrapper>
          <HomeButton icon="right">Home</HomeButton>
        </Link>
      </StyledBoard>
    </Wrapper>
  )
}

const Gummy = styled(Poppin)`
  position: absolute;
  bottom: 9px;
  right: -15px;
  width: 91px;
`

const Yellow = styled(Poppin)`
  position: absolute;
  bottom: 9px;
  left: 70px;
  width: 76px;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledStripeBg = styled(StripeBg)`
  --color-1: #752c28;
  --color-2: #712827;
`

const WaveTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 16px;
    background-color: var(--color-orange);
  }
`

const WaveBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleY(-1);

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 16px;
    background-color: var(--color-orange);
  }
`

const StyledWave = styled(Wave)`
  width: 100%;
  height: 16px;
`

const StyledBoard = styled(Board)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TextWrapper = styled.div`
  display: grid;
  justify-items: center;
`

const StatusCode = styled.div`
  font-size: 100px;
  margin-bottom: 20px;
  line-height: 1;
  text-align: center;
  color: var(--color-brown);
`

const Description = styled.div`
  font-size: 30px;
  line-height: 1;
  text-align: center;
  color: var(--color-brown);
  margin-top: -12px;
`

const HomeButton = styled(Button)`
  margin: 42px auto 0;
  text-transform: uppercase;
`
