import styled from '@emotion/styled'
import Poppin from '../Poppin'
import Wave from '../Wave'
import Dot from './Dot'
import Pin from './Pin'
import Round from './Round'

interface Props {
  className?: string
  title: string
}

export default function BoardTop({ className, title }: Props) {
  return (
    <Wrapper className={className}>
      <Cyan type="cyan" />
      <Red type="red" />
      <StyledPin />
      <Inner>
        <Header>
          <Title>{title}</Title>
          <Wave color="orange" direction="horizontal" />
        </Header>
        <RoundTopLeft />
        <RoundTopRight />
        <DotLeft />
        <DotRight />
      </Inner>
    </Wrapper>
  )
}

const Cyan = styled(Poppin)`
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  transform: translateY(-75%);
`

const Red = styled(Poppin)`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  transform: translateY(-75%);
`

const Wrapper = styled.div`
  position: fixed;
  width: calc(100% - 32px);
`

const StyledPin = styled(Pin)`
  position: absolute;
  top: 0;
  left: 50%;
  width: 210px;
  transform: translate(-50%, -99%);
`

const Inner = styled.div`
  position: relative;
`

const Header = styled.div`
  position: relative;
`

const RoundTopLeft = styled(Round)`
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  color: var(--color-orange);
  transform: rotate(90deg);
`
const RoundTopRight = styled(Round)`
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  color: var(--color-orange);
  transform: rotate(180deg);
`

const Title = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  font-size: 24px;
  line-height: 1;
  color: var(--color-white);
  height: 35px;
  background-color: var(--color-orange);
  border-radius: 32px 32px 0 0;
  margin-bottom: -1px;
`

const DotLeft = styled(Dot)`
  position: absolute;
  top: 13px;
  left: 20px;
  width: 12px;
`
const DotRight = styled(Dot)`
  position: absolute;
  top: 13px;
  right: 20px;
  width: 12px;
`
