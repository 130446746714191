import styled from "@emotion/styled";

interface Props {
  className?: string
}

export default function ArrowIcon({ className }: Props) {
  return (
    <Svg viewBox="0 0 11 12" className={className}>
      <Path d="M0 12V0H1.5V1.5H4.5V3H7.5V4.5H9V6H10.5V7.5H9V9H6V10.5H3V12H0Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: currentColor;
`
