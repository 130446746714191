import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function Dot({ className }: Props) {
  return (
    <Svg className={className} viewBox="0 0 14 14">
      <Path d="M12 0H2V2H0V12H2V14H12V12H14V2H12V0Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: var(--color-brown);
`
