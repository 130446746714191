import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

interface Props {
  className?: string
  animate?: boolean
}

export default function StripeBg({ className, animate }: Props) {
  return <Wrapper className={className} animate={animate} />
}

const move = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-50%, -50%);
  }
`

const Wrapper = styled.div<{ animate?: boolean }>`
  --stroke-width: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: repeating-linear-gradient(
    -45deg,
    var(--color-1, #eaa953) 0 var(--stroke-width),
    var(--color-2, #e09c4a) var(--stroke-width) calc(var(--stroke-width) * 2)
  );

  ${({ animate }) =>
    animate &&
    css`
      animation: ${move} 28s linear 0s alternate infinite;
    `}
`
