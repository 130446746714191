import { Authenticator } from '@aws-amplify/ui-react'
import { Amplify, Auth } from 'aws-amplify'
import type { AppProps } from 'next/app'
import '../styles/globals.css'
// prettier-ignore this needs to come after tailwind's global css
import '@aws-amplify/ui-react/styles.css'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/nextjs'
import { PolygonAmoyTestnet } from '@thirdweb-dev/chains'
import {
  coinbaseWallet,
  embeddedWallet,
  en,
  localWallet,
  metamaskWallet,
  ThirdwebProvider,
  walletConnect,
} from '@thirdweb-dev/react'
import getConfig from 'next/config'
import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import BoardBottom from '../components/common/Board/boardBottom'
import BoardTop from '../components/common/Board/boardTop'
import PageFrame from '../components/common/PageFrame'
import { PageTransition } from '../components/common/PageTransition'
import StripeBg from '../components/common/StripeBg'
import TapEffect from '../components/common/TapEffect'
import Wave from '../components/common/Wave'
import { getAppEnv } from '../lib/env'
import { GA } from '../lib/ga'
import { prettyLog } from '../lib/logger'
import ErrorBoundary from './ErrorBoundary'
import { useRouter } from 'next/router'

const { publicRuntimeConfig } = getConfig()

const userPoolId = process.env.NEXT_PUBLIC_AWS_USER_POOL_ID
const userPoolWebClientId = process.env.NEXT_PUBLIC_AWS_USER_POOL_WEB_CLIENT_ID

const authConfig = {
  Auth: {
    region: 'us-west-1',
    userPoolId,
    userPoolWebClientId,
  },
}
Amplify.configure(authConfig)
Auth.configure(authConfig)

function PoppinApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    prettyLog('POPPIN WEB APP VERSION', publicRuntimeConfig?.version)
    prettyLog('POPPIN WEB APP ENV', getAppEnv())
  }, [])

  const router = useRouter()
  const isWelcomePage = router.pathname === '/welcome'
  if (isWelcomePage) {
    return (
      <ErrorBoundary>
        <RecoilRoot>
          <PageFrame>
            <Component {...pageProps} />
            <PageTransition />
            <TapEffect />
          </PageFrame>
        </RecoilRoot>
      </ErrorBoundary>
    )
  }

  return (
    <>
      <GA />
      <Authenticator components={signInComponents} formFields={formFields}>
        {({ user }) => {
          Sentry.setUser({
            email: user?.attributes?.email,
            username: user?.username,
            version: publicRuntimeConfig?.version,
          })
          return (
            <ErrorBoundary>
              <RecoilRoot>
                <ThirdwebProvider
                  activeChain={PolygonAmoyTestnet}
                  clientId="a369dbe96f110bd6bfd6208ddfe65b20"
                  locale={en()}
                  supportedWallets={[
                    metamaskWallet(),
                    coinbaseWallet({ recommended: true }),
                    walletConnect(),
                    localWallet(),
                    embeddedWallet({
                      auth: {
                        options: ['email', 'google', 'apple', 'facebook'],
                      },
                    }),
                  ]}
                >
                  <PageFrame>
                    <Component {...pageProps} />
                    <PageTransition />
                    <TapEffect />
                  </PageFrame>
                </ThirdwebProvider>
              </RecoilRoot>
            </ErrorBoundary>
          )
        }}
      </Authenticator>
    </>
  )
}

const signInComponents = {
  Header() {
    return (
      <PageFrame>
        <StyledStripeBg />
        <WaveBottom>
          <StyledWave direction="horizontal" color="orange" />
        </WaveBottom>
        <StyledBoardTop title="Poppin" />
        <StyledBoardBottom />
      </PageFrame>
    )
  },
}

const formFields = {
  signIn: {
    username: {
      label: 'Email',
      placeholder: 'info@example.com',
    },
    password: {
      label: 'Password',
      placeholder: '********',
    },
  },
  signUp: {
    username: {
      placeholder: 'Email Address',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Email Address',
    },
  },
}

export default PoppinApp

const StyledStripeBg = styled(StripeBg)`
  --color-1: #752c28;
  --color-2: #712827;
`

const WaveBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleY(-1);

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 16px;
    background-color: var(--color-orange);
  }
`

const StyledWave = styled(Wave)`
  width: 100%;
  height: 16px;
`

const StyledBoardTop = styled(BoardTop)`
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  padding-bottom: 220px;
  background-color: var(--color-cream);
  border-radius: 30px 30px 0 0;
`

const StyledBoardBottom = styled(BoardBottom)`
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  padding-top: 220px;
  background-color: var(--color-cream);
  border-radius: 0 0 30px 30px;
`
