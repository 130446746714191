import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ArrowIcon from '../ArrowIcon'
import LoadingIcon from '../LoadingIcon'
import Round from './Round'

interface Props {
  className?: string
  isLoading?: boolean
  disabled?: boolean
  icon: 'left' | 'right' | 'none'
  children: React.ReactNode
  onClick?(): void
}

export default function Button({
  className,
  icon,
  children,
  isLoading,
  disabled,
  onClick,
}: Props) {
  return (
    <Wrapper
      className={className}
      onClick={!isLoading ? onClick : undefined}
      disabled={disabled}
    >
      <RoundWithLeft />
      <Inner>
        <StyledArrowIcon icon={icon} />
        {!isLoading ? <Text>{children}</Text> : <StyledLoadingIcon />}
      </Inner>
      <RoundWithRight />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: start;
  min-width: 184px;
  width: fit-content;
  height: 60px;
  user-select: none;
  transition: transform 0.1s ease-in-out 0s;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}

  &:active {
    transform: scale(0.96);
  }
`

const StyledRound = styled(Round)`
  --round-color: var(--button-color, var(--color-brown));
  width: 25px;
`

const RoundWithLeft = styled(StyledRound)`
  margin-right: -1px;
  transform: scaleX(-1);
`

const RoundWithRight = styled(StyledRound)`
  margin-left: -1px;
`

const Inner = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding: 0 20px;
  background-color: var(--color-white);

  &::before {
    --margin: 5px;
    content: '';
    display: block;
    position: absolute;
    top: var(--margin);
    left: 0;
    width: 100%;
    height: calc(100% - var(--margin) * 2);
    background-color: var(--button-color, var(--color-brown));
  }
`

const StyledArrowIcon = styled(ArrowIcon)<{ icon: Props['icon'] }>`
  position: absolute;
  top: 50%;
  width: 10.5px;
  transform: translateY(-50%);
  color: var(--color-white);

  ${({ icon }) =>
    icon === 'left' &&
    css`
      left: 0;
      transform: translateY(-50%) scaleX(-1);
    `}

  ${({ icon }) =>
    icon === 'right' &&
    css`
      right: 0;
    `}

  ${({ icon }) =>
    icon === 'none' &&
    css`
      display: none;
    `}
`

const Text = styled.div`
  position: relative;
  font-size: 36px;
  line-height: 1;
  color: var(--color-white);
  text-align: center;
`

const StyledLoadingIcon = styled(LoadingIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
