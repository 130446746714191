import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useRecoilState, useRecoilValue } from 'recoil'
import pageTransitionAtom from '../../../atoms/pageTransitionAtom'

const DURATION = 1000

export function usePageTransition() {
  const [isOpen, setIsOpen] = useRecoilState(pageTransitionAtom)

  const open = (callback: () => void) => {
    setIsOpen(true)
    window.setTimeout(callback, DURATION)
  }
  const close = (callback: () => void) => {
    setIsOpen(false)
    window.setTimeout(callback, DURATION)
  }
  const closeAndOpen = (callback: () => void) => {
    close(() => {
      callback()
      open(() => null)
    })
  }

  return {
    isOpen,
    open,
    close,
    closeAndOpen,
  }
}

export function Link({
  className,
  disable,
  href,
  onClick,
  children,
}: {
  className?: string
  disable?: boolean
  href?: string
  children: React.ReactNode
  onClick?(): void
}) {
  const router = useRouter()
  const { closeAndOpen } = usePageTransition()

  const handleClick = () => {
    if (onClick) {
      closeAndOpen(onClick)
    } else if (href) {
      closeAndOpen(() => router.push(href))
    }
  }

  return (
    <a className={className} onClick={!disable ? handleClick : undefined}>
      {children}
    </a>
  )
}

export function PageTransition() {
  const isOpen = useRecoilValue(pageTransitionAtom)

  return isOpen ? <CurtainOpen /> : <CurtainClose />
}

function CurtainOpen() {
  return (
    <Wrapper>
      <Open />
      <Open />
    </Wrapper>
  )
}

function CurtainClose() {
  return (
    <Wrapper>
      <Close />
      <Close />
    </Wrapper>
  )
}

const slideIn = keyframes`
  from {
    transform: translateX(-101%);
  }
  to {
    transform: translate(0, 0);
  }
`
const slideOut = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translateX(-101%);
  }
`
const radiusIn = keyframes`
  from {
    border-radius: 0 0 200% 0;
  }
  to {
    border-radius: 0;
  }
`
const radiusOut = keyframes`
  from {
    border-radius: 0;
  }
  to {
    border-radius: 0 0 200% 0;
  }
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9;
  pointer-events: none;
`

const Rect = styled.div`
  position: absolute;
  top: 0;
  width: 51%;
  height: 100%;

  &:nth-child(1) {
    left: 0;
  }
  &:nth-child(2) {
    right: 0;
    transform: scaleX(-1);
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-red);
  }
`

const Open = styled(Rect)`
  &::after {
    animation: ${radiusOut} 0.8s ease-in 0s both,
      ${slideOut} 0.6s ease-in-out 0.4s both;
  }
`

const Close = styled(Rect)`
  &::after {
    animation: ${slideIn} 0.6s ease-in-out 0s both,
      ${radiusIn} 0.7s ease-out 0.2s both;
  }
`
