import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function LoadingIcon({ className }: Props) {
  return <Wrapper className={className} />
}

const loading = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
`

const Wrapper = styled.div`
  --icon-size: 30px;
  box-sizing: border-box;
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 50%;

  &::before,
  &::after {
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &::before {
    border: solid 6px rgba(255, 255, 255, 0.2);
  }
  &::after {
    border: solid 6px;
    border-color: var(--color-white) transparent transparent transparent;
    animation: ${loading} 1.2s linear 0s infinite;
  }
`
